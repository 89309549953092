html{
  height: -webkit-fill-available;
}
body {
  margin: 0;
  color: grey;
  background-color: #FFF;
  font-family: 'lato', 'Helvetica', 'Arial', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height: 100%; */
}
#root{
  /* height: 100%; */

}
.container{
  height: 100%;
}
.pageContainer{
  /* height: calc(100vh - 210px); */
  display: flex;
  justify-content: center;
  /* height: calc(100vh - 270px); */
  /* height: 78vh; */
  /* height: 70vh; */
  height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.react-datepicker-wrapper{
  display: block !important;
  width: 100%;

}
.newBtn{
  border-radius: 50px;
  opacity: 1;
  text-transform: uppercase;
  padding: 20px;
  font: normal normal bold 20px/24px Helvetica;
  border: none;
}
.greenDecitre{
  background: #26623E 0% 0% no-repeat padding-box;
}
.grayDarkDecitre{
  background: #183E27 0% 0% no-repeat padding-box;
}
.grayDecitre{
  background: #63656A 0% 0% no-repeat padding-box;
}
.mintDecitre{
  background: #49FFCB 0% 0% no-repeat padding-box;
}
.white{
  color: white;
}
.grayDarkDecitreText{
  color: #183E27;
}
.btn-orange, .btn-orange:hover{
  background-color: #EA7F00;
  border-color: #EA7F00;
  color: white;
}
button{
  border: none;
}
.btn-green, .btn-green:hover{
  background-color: #64B350;
  border-color: #64B350;
  color: white;
}
.btn-red, .btn-red:hover{
  background-color: #B61800;
  border-color: #B61800;
  color: white;
}
.btn-blue, .btn-blue:hover{
  background-color: #265F8F;
  border-color: #265F8F;
  color: white;
}
.btn-s{
  font-size: 1.5vw;
  width: 15vw;
}
.btn-m{
  font-size: 2vw;
  width: 20vw;
}
.btn-l{
  font-size: 3vw;
  width: 25vw;
}
.btn-xl{
  font-size: 5vw;
  width: 40vw;
}

.titleGreen{
  width: 100%;
  text-align: start;
  /* margin-bottom: 50px; */
  font: normal normal bold 30px/34px Helvetica;
  letter-spacing: 0px;
  color: #26623E;
}
.m10-left{
  margin-left: 10px;
}

.m10-right{
  margin-right: 10px;
}
.form-control::placeholder{
  font: normal normal normal 20px/34px Helvetica;
  letter-spacing: 0px;
  color: #63656A;
  /* color: red; */
  opacity: 0.4;
  vertical-align: -webkit-baseline-middle;
}
button:focus {
  outline: none;
}
.noMargin{
  margin: 0 !important;
}