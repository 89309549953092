.container{
    background-color: white;
    border: 1px solid #022F56;
    /* height: 80%; */
    width: 100%;
    padding: 40px 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    min-height: 496px
}
.cartBlockTitle{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin:0 0 40px  0;

}
.cartBlockTotal{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #63656A;
    padding-top: 20px;
}
.bookLogo{
    height: 50px;
    padding-right: 10px;
}
.binLogo{
    height: 20px;
    padding-left: 10px;
    cursor: pointer;
}
.bookTitle{
  width:250px;
  display: block;
}
.bookBlock{
    width: 100%;
    height: 100%;
    /* margin: auto; */
    display: flex;
    flex-direction: column;
    align-items: space-around;
    /* justify-content: space-between; */
}
.bookItem{
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: center;
    font: normal normal normal 24px/34px Helvetica;
    letter-spacing: 0px;
    color: #63656A;
    margin-bottom: 25px;
}
.cartTitle{
    font: normal normal bold 30px/34px Helvetica;
    letter-spacing: 0px;
    color: #26623E;
    opacity: 1;
}
.trash{
    cursor: pointer;
    margin-left: 10px;
}
.cartTotal{
    font: normal normal bold 24px/34px Helvetica;
    letter-spacing: 0px;
    color: #26623E;
    opacity: 1;
}

.priceBox{
    display: flex;
    align-items: center;
}