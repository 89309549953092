.page{
    padding: 0;
    max-height: calc(100vh - 263px);
}
.container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.userContainer{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.content{
    display: flex;
    margin-top: 90px;
    /* height: 100%; */
    justify-content: space-around;
    width: 100%;
    padding: 0 100px;
    margin-bottom: 50px;

}
.col{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-around;
     */
}
.loginCol{
    display: flex;
    flex-direction: column;
    /* height: 100%; */
    align-items: flex-start;
    justify-content: space-between;
    text-align: center;
    margin-right: 20px;
}
.loginCol button:nth-child(n){
    margin-top: 80px;
}
.loginCol button:nth-child(2n+1){
    margin-top: 60px;
}
.btn{
    width: 50%;
}
.title{
    width: 100%;
    text-align: start;
    /* margin-bottom: 50px; */
    font: normal normal bold 30px/34px Helvetica;
    letter-spacing: 0px;
    color: #26623E;
}
.returnBox{
    display: flex;
    align-items: flex-end;
    height: 100%;
    position: relative;
    right: 100px;

}
.giftCardBox{
    width: 100%;
}
.h100{
    height: 100%;
}