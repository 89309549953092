.nav{
    /* background-color: #F4F4F4; */
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    background: #26623E 0% 0% no-repeat padding-box;
    height: 115px;
}
.logo{
    padding: 10px;
    width: 80px;
}
.leftarrow{
    stroke-width: 2px;
    margin-right: 10px;
}
.mainTitle{
    font: normal normal bold 40px/48px Helvetica;
letter-spacing: 0px;
color: #FFFFFF;
opacity: 1;
margin: 0;
padding-left: 40px;
}
.header{
    display: flex;
    align-items: center;
}