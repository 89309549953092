.page{
    padding: 0;
    max-height: calc(100vh - 263px);
}
.row{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    /* height: 78vh; */
}
.rowGiven{
    flex-direction: column;
    align-items: center;
    
}
.titleRefurb{
    font: normal normal bold 40px/48px Helvetica;
    letter-spacing: 0px;
    color: #63656A;
    margin-top: 90px;
}
.title{
    font: normal normal bold 30px/34px Helvetica;
    letter-spacing: 0px;
    color: #26623E;
    margin-top: 80px;
}
.titleGivenSentence{
    margin-top: 100px;
    font: normal normal bold 40px/48px Helvetica;
    letter-spacing: 0px;
    color: #63656A;
}
.paddingLeft{
    width: 100%;
    /* padding-left: 240px; */
}
.btnBox{
    display: flex;
    margin-top: 80px;
    justify-content: flex-end;
    margin-bottom: 60px;
}
.w75{
    width: 75%;
}
.helpText{
    font: normal normal normal 24px/34px Helvetica;
    letter-spacing: 0px;
    color: #63656A;
    opacity: 1;
    margin-bottom: 80px;
}

.customHeight{
    height: -webkit-fill-available;
}