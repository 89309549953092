.container{
    /* height: 34vh; */
}
.input{
    /* width: 50%; */
    width: 100%;
    height: 60px;

}
.input[type=number]::-webkit-inner-spin-button, 
.input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
.inputContainer{
    display: flex;
    flex-direction: column;
}
.form{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 0;
    margin-top: 60px;
    /* margin-left: 240px; */
}

.form img{
    margin-left: 40px;
}

button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.error{
    /* font-family: publicSans-ExtraBold; */
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    align-items: center;
    background-color: unset;
    color: #FF3A00;
    border: unset;
    text-align: center;
    font-size: 1.5em;
    line-height: 30px;
}
.error span{
    margin-top: 20px;
}