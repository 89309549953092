.container{
    /* background-color: #212C3F; */
    background: #183E27 0% 0% no-repeat padding-box;
    height: 67px;
    /* height: 80px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}
.row{
    width:100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: inherit;
}
.storeName{
    color: white;
}
.cart{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.cart img{
    margin-right: 10px;
}
.cartValue, .cartCount{
    margin-right: 10px;
    height: 30px;
    color: #49FFCB;
    /* background-color: white; */
    width: 100px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 5px;
    margin-right:  40px;
    font-size: 30px;
}

.cartBarLogo{
    height: 52px;
}
.cartBarText{
    font-weight: bold;
    padding-right: 20px;
}