.flashMessageBoX{
    display: flex;
    align-items: center;
    width: 100%;
    position: absolute;
    background: white;
    z-index: 1;
}
.symbol{
    height: 24px;
}