.overlay {
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: fixed;
    background-color: #777c80;
    opacity: 0.5;
}

.overlay__inner {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}

.overlay__content {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}

.loader {
    
    font-size: 50px;
    margin: 50px auto;
    text-indent: -9999em;
    width: 11em;
    height: 11em;
    border-radius: 50%;
    background: #004176;
    background: -moz-linear-gradient(left, #004176 10%, rgba(0,65,118, 0) 42%);
    background: -webkit-linear-gradient(left, #004176 10%, rgba(0,65,118, 0) 42%);
    background: -o-linear-gradient(left, #004176 10%, rgba(0,65,118, 0) 42%);
    background: -ms-linear-gradient(left, #004176 10%, rgba(0,65,118, 0) 42%);
    background: linear-gradient(to right, #004176 10%, rgba(0,65,118, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .loader:before {
    width: 50%;
    height: 50%;
    background: #004176;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
  }
  .loader:after {
    background: #777c80;
    width: 75%;
    height: 75%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
  @-webkit-keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load3 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  