.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.btn{
    margin-top: 50px !important;
}
.btnBack{
    width: 70%;
}

.inputBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font: normal normal bold 20px/34px Helvetica;
    letter-spacing: 0px;
    color: #63656A;
    /* margin-top: 45px; */
    width: 100%;
}
.resendBtn{
    border: none;
    background: transparent;
    text-transform: uppercase;
    font-style: italic;
    text-decoration: underline;
}
.btnBox{
    display: flex;
    align-items: center;
    margin-top: 50px;
}
.returnBox{
    position: relative;
    right: 100px;
}