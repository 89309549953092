.row{
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0 260px 0 220px;
    
}
.giveText{
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
}
.conditions{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.text{
    height: 60%;
    max-height: 477px;
    overflow: scroll;
    border: 1px solid #022F56;
    padding: 20px;
    width: auto;
}
.textTitle{
    text-align: center;
    font-weight: bold;
}
.btnBoxAll{
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: center
}
.btnBox{
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-between;
    /* height: 240px; */
}
.btnBox:first-child{
    margin-right: 40px;
}
.column{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 77vh;
}
.bigBtn{
    width: 520px;
    height: 240px;
    background: #63656A 0% 0% no-repeat padding-box;
    border-radius: 40px;
    opacity: 1;
    text-align: center;
    font: normal normal bold 50px/50px Helvetica;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-bottom: 90px;
}
.leftAlign{
    align-items: flex-start;
    justify-content: flex-start;
}
.rightAlign{
    align-items: flex-end;
    justify-content: flex-end;

}
.iconAccept{
    margin-right: 10px;
}
.btnBoxInLine{
    display: flex;
    width: 100%;
}
.titleGenerosity{
    /* margin-top: 100px; */
    margin-bottom: 50px;
    font: normal normal bold 40px/34px Helvetica;
    letter-spacing: 0px;
    color: #26623E;
}
.helpText{
    font: normal normal normal 24px/34px Helvetica;
    letter-spacing: 0px;
    color: #63656A;
    opacity: 1;
}
