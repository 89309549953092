.loginForm{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
    height: 100%;
    margin-top: 80px;
}
.btn{
    width: 100%;
    /* margin-top: 20px; */
    margin-top: auto !important;
}
.btnBack{
    width: 70%;
}
.title{
    width: 100%;
    text-align: start;
    /* margin-bottom: 50px; */
    font: normal normal bold 30px/34px Helvetica;
    letter-spacing: 0px;
    color: #26623E;
}
.inputBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font: normal normal bold 20px/34px Helvetica;
    letter-spacing: 0px;
    color: #63656A;
    /* margin-top: 45px; */
    width: 100%;
}