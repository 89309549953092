.form{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
}
.content{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    margin-bottom: 50px;
}
.rowStep1{
    display: flex;
    justify-content: center;
    width: 100%;
    /* align-items: flex-end; */
}
.rowStep2{
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 83%; */

}
.checkBoxOptin{
    display: flex;
    align-items: center;
    font-size: 0.85em;
}
.rowTitle{
    /* height: 15%; */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    text-align: center;
    margin-top: 90px;
}
.rowTitle > p{
    text-align: left;
    font: normal normal normal 24px/34px Helvetica;
    letter-spacing: 0px;
    color: #63656A; 
    width: 100%;
}
.rowBottom{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}
.honorCol{
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
}
.checkBox{
    margin-top: 44px;   
}
.honorText{
    font: normal normal normal 24px/29px Helvetica;
    letter-spacing: -0.8px;
    color: #63656A;
}
.inputBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font: normal normal bold 20px/34px Helvetica;
    letter-spacing: 0px;
    color: #63656A;
    /* margin-top: 45px; */
    width: 100%;
}
.inputBox label{
    margin-bottom: 0;
}
.checkBoxContainer{
    display: flex;
    flex-direction: column;

    align-items: flex-start;
    width: 80%;
}
.checkBox{
    display: flex;
    flex-direction: row;
    /* align-items: baseline; */
}
input[type='checkbox']{
    background: transparent linear-gradient(180deg, #EBEBEB 0%, #FDFDFD 100%) 0% 0% no-repeat padding-box;
    border: 1px solid #63656A;
    border-radius: 8px;
    opacity: 1;
    width: 32px;
    height: 32px;
    margin-right: 10px;

}