.objectPdf{
    width: 100%;
    height: 100%;
}
.endSentence{
    text-align: center;
}
.title{
    margin-top: 80px;
    width: 100%;
    text-align: center;
    /* margin-bottom: 50px; */
    font: normal normal bold 30px/34px Helvetica;
    letter-spacing: 0px;
    color: #26623E;
}