.container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 28px 10px 20px;
    /* height: 45px; */
    border-top: 1px solid #63656A;
}
.logo{
    padding-left: 10px;
    height: 20px;
    width: auto;
}
.partnerText{
    font: normal normal normal 24px/60px Helvetica;
    vertical-align: middle;
}