.buttonRow{
    display: flex;
    width: 100%;
    justify-content: space-around;
}
.bookRow{
    display: flex;
    justify-content: flex-start;
    width: 80%;
    max-height: 50%;
    margin-top: 90px;
    margin-bottom: 45px;
}
.bookInfo{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 100%;
}
.imageResponsive{ 
    max-width: 100%;
    height: 100%;
}
.titleBook{
    font: normal normal bold 30px/34px Helvetica;
    letter-spacing: 0px;
    color: #63656A;
}
.authors{
    /* font-style: italic; */
    font: normal normal normal 24px/34px Helvetica;
    letter-spacing: 0px;
    color: #63656A;
}
.detailsList{
    padding-top: 50px;
}
.detailsTitle{
    display: inline-block;
    width: 100px;
    padding-bottom: 15px;
    font: normal normal bold 24px/34px Helvetica;
    letter-spacing: 0px;
    color: #63656A;
}
.detailsList span{
    font: normal normal normal 24px/34px Helvetica;
    letter-spacing: 0px;
    color: #63656A;
}
.price{
    /* border: 1px solid #022F56;
    border-radius: 5px;
    background-color: #F4F4F4; */
    font-weight: bold;
    padding: 5px;
    color: #49FFCB;
}
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 230px;
    padding-top: 40px
}
.title{
    margin-bottom: 50px;
    font: normal normal bold 30px/34px Helvetica;
    letter-spacing: 0px;
}