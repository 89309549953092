.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #26623E;
    /* height: 92.4vh; */
    /* height: 100vh; */
    height: 100%;
}
.titleBox{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    padding: 60px 100px;
}
.noMarginTop{
    margin-top: 0 !important;
}
.mainTitle{
    padding-left: 40px;
    font: normal normal bold 60px/60px Helvetica;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.globalBtnBox{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* margin-top: 70px; */
    height: 280px;

}
.mainBtnBox{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 640px;
    height: 100%;
    /* height: 280px; */
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 40px;
    opacity: 1;
    cursor: pointer;
    color: #63656A;
    text-align: left;
    font: normal normal bold 50px/50px Helvetica;
    letter-spacing: 0px;
    opacity: 1;
}
.mainBtnBox > div, .secondaryBtnBox div {
    display: flex;
    justify-content: center;
    align-items: center;
}
.secondaryBtnBox{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 40px;
    height: 100%;

}
.secondaryBtnBox div{
    width: 400px;
    height: 120px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 40px;
    text-align: center;
    font: normal normal bold 30px/34px Helvetica;
    letter-spacing: 0px;
    color: #63656A;
    opacity: 1;
    cursor: pointer;

}
.logoTextContainer{
    display: flex;
    justify-content: space-between;
    height: 200px;
    width: calc(640px + 40px + 400px);
    align-items: center;
    margin-bottom: 60px;
}
.logoTextBox{
    display: flex;
    flex-direction: column;
    width: 333px;
    height: 160px;
    margin-top: 30px;
    margin-bottom: 30px;

}
.pictoText{
    background: #183E27 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    letter-spacing: 0px;
    color: #FFFFFF;
    padding: 50px 20px 20px;
    text-align: center;
}
.pictoText p{
    font: normal normal bold 18px/22px Helvetica;
    letter-spacing: 0px;
    color: #FFFFFF;
}
.icon{
    position: relative;
    top: 30px;
    width: 64px;
}

/* ------------------- STEP 2 ---------------- */
.headerIntro{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    font: normal normal bold 40px/48px Helvetica;;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    padding: 25px 100px
}
.headerIntro > h1 {
    padding-left: 40px;
}
.content{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    height: 100%;
    width: 100%;
    /* padding-top: 90px; */
}
.content > div:first-child{
    font-size: 24px;
    width: 778px;
    margin: 83px 240px;
}
.link{
    display: flex;
    align-items: center;
    text-align: left;
    text-decoration: underline;
    font: normal normal bold 24px/34px Helvetica;
    letter-spacing: 0px;
    color: #26623E;
    opacity: 1;
    cursor: pointer;
}
.link img{
    padding-left: 15px;
}
.btnBox{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-bottom: 60px;
}
button > i{
    vertical-align: top;
    font-size: x-large;
}

/* ----------STEP 3------------- */

.iconSubtitleBox{
    display: flex;
    align-items: center;
    justify-self: center;
    text-align: left;
    font: normal normal bold 30px/34px Helvetica;
    letter-spacing: 0px;
    color: #26623E;
    opacity: 1;
    margin-bottom: 16px;
    margin-top: 64px;

}
.iconSubtitleBox > p{
    vertical-align: middle;
    margin: 0 15px;
}
.iconSubtitleBox > img{
    position: relative;
    bottom: 5px;
}
.red{
    color: #DB1720;
}
.mB34{
    margin-bottom: 34px;
}