.loginForm{
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    justify-content: space-between;
    width: 100%;
    height: 100%;
}
.btnBox{
    display: flex;
    justify-content: space-between;
    /* margin-top: auto; */
}
.btn{
    width: 100%;
    margin-top: 20px;
}
.btnBack{
    width: 70%;
}
.forgotLink{
    cursor: pointer;
    font: normal normal normal 16px/34px Helvetica;
    letter-spacing: 0px;
    color: #26623E;
    text-align: right;
    width: 100%;
}
.inputBox{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    font: normal normal bold 20px/34px Helvetica;
    letter-spacing: 0px;
    color: #63656A;
    margin-top: 45px;
    width: 100%;
}
.inputBox label{
    margin-bottom: 0;
}
.returnBox{
    display: flex;
    align-items: flex-end;
    height: 100%;
    position: relative;
    right: 100px;

}
.flexCol{
    display: flex;
    flex-direction: column;
    height: 100%;
}